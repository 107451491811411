import { Box, type SpacingAlias } from "@yahoo/uds";
import { I13nAnchor } from "@yahoo-commerce/i13n";
import LocalLogoSvg from "@/svgs/localLogo.svg";

interface LocalLogoProps {
  spacingBottom?: SpacingAlias;
}

export const LocalLogo = ({ spacingBottom = "6" }: LocalLogoProps) => {
  return (
    <Box spacingBottom={spacingBottom}>
      <I13nAnchor
        href="https://local.yahoo.com/"
        aria-label="Yahoo Local"
        dataYlk={{
          elm: "logo",
          slk: "yahoo local",
        }}
      >
        <LocalLogoSvg height="25" />
      </I13nAnchor>
    </Box>
  );
};

export default LocalLogo;
